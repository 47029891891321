<template>
  <div class="">
    <input class="admin_input fz16" v-model="brand_account" autocomplete="off" name="brand_account" placeholder="输入品牌编码" type="text">
    <input class="admin_input fz16" v-model="account" name="account" autocomplete="off" placeholder="输入您的账号" type="text">
    <input class="admin_pw admin_input fz16" v-model="password" name="password" autocomplete="off" placeholder="输入您的密码" type="password">
  </div>
  <div class="login_btn_box dis-flex4">
    <div class="login_btn center color-fff fz16" @click="toLogin('login')">登录</div>
  </div>
</template>

<script>
import { reactive ,toRefs} from 'vue'
import { loginBrand } from '@/api/login'
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'
import CryptoJS from 'crypto-js'
export default {
  name:'Admin',
  setup(){
    // const router = useRouter()
    const state = reactive({
      account:'',
      password:'',
      brand_account:''
    })
    const store = useStore()
    const toLogin=async()=>{
      const { data } = await loginBrand({
        "account": state.account,
        "password": state.password,
        "brand_account":state.brand_account
      })
      const {token,type,appid,dealer_detail,time}=data
      const str={token,type,appid,dealer_detail,time,offWeb:true}
      localStorage.setItem('dataValue',JSON.stringify(str))
      localStorage.setItem('api_token',data.token)
      localStorage.setItem('typeData',2)
      store.commit('AddType',{typeData:2})
      store.commit('AddToken',{api_token:data.token})
      ElNotification({
        title: 'Success',
        message: '登录成功',
        type: 'success',
        duration: 3000,
      })
      var aseKey = "12345678" 
      const dataValue = CryptoJS.AES.encrypt(localStorage.getItem('dataValue'), CryptoJS.enc.Utf8.parse(aseKey), {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString();
        window.location.href=`https://store.jhscrm.com?dataValue=${dataValue}`
      // router.push({ path: `/` })
    }
    return {...toRefs(state),toLogin}
  }
}
</script>

<style lang="scss" scoped>
  .admin_input{
    width:100%;
    border:1px solid #DDDEE1;
    padding:12px 16px;
    margin-bottom:22px;
    color:#999;
    border-radius: 6px;
  }
  .admin_input:focus{
    outline:none;
    border:1px solid #0F6FFF ;
    color:#333
  }
  .login_btn_box{
    position: absolute;
    bottom:60px;
    left:0;
    right:0;
    .login_btn{
      width:380px;
      background: #0F6FFF;
      padding:12px 0;
      border-radius: 6px;
      cursor: pointer;
    }
  }
</style>